.message {
  margin-bottom: 10;
  padding-left: 0 !important;
}

.sender {
  padding-right: 7.5px;
}

.bubble {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 8px;
}

.bubble.recipient {
  background-color: #dfe6e9;
  margin-left: 80px;
  padding: 5px 10px;
}

.bubble.sender {
  background-color: #bbdefb;
  margin-left: 10px;
  margin-right: 25px;
}

.bubble span,
.bubble p {
  font-size: unset;
}
