* {
  font-family: Roboto;
}

.edit-page {
  z-index: 99;
}

* {
  font-size: 12px;
}

.image-preview-hover img {
  display: none;
}

.preview-container {
  position: fixed;
  width: 325px;
  height: 100%;
  object-fit: contain;
  top: 50px;
  left: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}

.screenshot-card {
  max-width: 345px;
}

.preview-container img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.screenshot-card .media {
  height: 0;
  padding-top: 56.25%;
  background-position: top;
}

.screenshot-card .media:hover + .preview-container {
  display: block;
}

#main-content {
  max-width: 1440px;
  margin: 0 auto;
}

td.MuiTableCell-root {
  position: relative;
}
